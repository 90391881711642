import Vue from 'vue'
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAvatar,
  VAutocomplete,
  VBadge,
  VBtn,
  VBottomNavigation,
  VCalendar,
  VCard,
  VCardSubtitle,
  VCardTitle,
  VCardText,
  VCardActions,
  VCarousel,
  VCarouselItem,
  VCol,
  VCheckbox,
  VChip,
  VCombobox,
  VContainer,
  VDataTable,
  VDialog,
  VDivider,
  VDatePicker,
  VEditDialog,
  VFileInput,
  VFooter,
  VForm,
  VFadeTransition,
  VHover,
  VIcon,
  VImg,
  VList,
  VListGroup,
  VListItem,
  VListItemContent,
  VListItemGroup,
  VListItemTitle,
  VListItemSubtitle,
  VListItemIcon,
  VListItemAvatar,
  VListItemAction,
  VListItemActionText,
  VMain,
  VMenu,
  VNavigationDrawer,
  VOverlay,
  VPagination,
  VParallax,
  VProgressCircular,
  VProgressLinear,
  VRadioGroup,
  VRadio,
  VRating,
  VResponsive,
  VRow,
  VSpacer,
  VSwitch,
  VSelect,
  VSlider,
  VSlideGroup,
  VSlideItem,
  VSimpleTable,
  VSheet,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSubheader,
  VToolbarTitle,
  VToolbar,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VTabsSlider,
  VTextField,
  VTextarea,
  VTimeline,
  VTimelineItem,
  VTooltip,
  VWindow,
  VWindowItem
} from 'vuetify/lib'
import { Ripple, Resize, Scroll } from 'vuetify/lib/directives'
import es from 'vuetify/es5/locale/es'

import theme from './theme.vuetify'
import icons from './icons.vuetify'

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAvatar,
    VBadge,
    VBtn,
    VBottomNavigation,
    VCard,
    VCarousel,
    VCarouselItem,
    VCardSubtitle,
    VCardTitle,
    VCardText,
    VContainer,
    VCol,
    VDatePicker,
    VCalendar,
    VEditDialog,
    VSimpleTable,
    VAutocomplete,
    VFadeTransition,
    VFooter,
    VHover,
    VMain,
    VListItemActionText,
    VOverlay,
    VParallax,
    VCheckbox,
    VChip,
    VCardActions,
    VCombobox,
    VDataTable,
    VDialog,
    VDivider,
    VFileInput,
    VForm,
    VIcon,
    VImg,
    VList,
    VListGroup,
    VListItem,
    VListItemContent,
    VListItemGroup,
    VListItemTitle,
    VListItemSubtitle,
    VListItemIcon,
    VListItemAvatar,
    VListItemAction,
    VMenu,
    VNavigationDrawer,
    VPagination,
    VProgressCircular,
    VProgressLinear,
    VRating,
    VToolbarTitle,
    VSpacer,
    VSlider,
    VToolbar,
    VSwitch,
    VSelect,
    VSubheader,
    VRadioGroup,
    VRadio,
    VRow,
    VResponsive,
    VStepper,
    VStepperContent,
    VStepperHeader,
    VStepperItems,
    VStepperStep,
    VSheet,
    VSlideGroup,
    VSlideItem,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VTabsSlider,
    VTextField,
    VTextarea,
    VTimeline,
    VTimelineItem,
    VTooltip,
    VWindow,
    VWindowItem
  },
  directives: {
    Ripple,
    Resize,
    Scroll
  }
})

export default new Vuetify({
  theme,
  lang: {
    locales: { es },
    current: 'es'
  },
  icons
})
