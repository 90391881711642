import axios from 'axios'
import store from '../../store'

export const ApiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_V1
})

export const DefaultInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_DEFAULT
})

ApiInstance.interceptors.request.use((config) => {
  const accessToken = null // store.state.authModule.accessToken
  if (accessToken) {
    config.withCredentials = true
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

ApiInstance.interceptors.response.use(
  response => {
    if (response.data && response.status === 200 && response.data.success) {
      return response.data
    } else if (response.data === '' && response.status === 200) {
      return response.data
    } else {
      return Promise.reject(response.data)
    }
  },
  error => {
    if (error.response.status === 403) {
      store.dispatch('authModule/setLogOut')
      location.reload()
    }
    return Promise.reject(error.response.data)
  }
)
