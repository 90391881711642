// CustomIcons
// import customIcon from '@/icons/customIcon'

const icons = {
  iconfont: 'fa',
  values: {
    // customIcon: {
    //   component: customIcon,
    //   props: {
    //     name: 'customIcon'
    //   }
    // }
  }
}

export default icons
