const theme = {
  light: true,
  themes: {
    light: {
      primary_1: '#231F27', // Medium yellow
      secondary_1: '#2E1A86', // Lime green
      secondary_2: '#1C4496', // Bright cyan
      font_1: '#FFFFFF',
      font_2: '#04D99D',
      font_3: '#4FBD93'
    }
  }
}

export default theme
